/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
         $('#testimonial-slider').slick({
          prevArrow: "<a href='#' class='slick-prev'><span class='fa fa-chevron-circle-left text-primary'></span></a>",
          nextArrow: "<a href='#' class='slick-next'><span class='fa fa-chevron-circle-right text-primary'></span></a>",
          speed: 800,
          autoplay: true,
          autoplaySpeed: 12000,


        });


         $('.settlements-slider').slick({
          prevArrow: "<a href='#' class='slick-prev'><span class='fa fa-2x fa-chevron-circle-left text-primary'></span></a>",
          nextArrow: "<a href='#' class='slick-next'><span class='fa fa-2x fa-chevron-circle-right text-primary'></span></a>",
          speed: 500,
          autoplay: true,
          autoplaySpeed: 2000,
          centerMode: true,
            infinite: true,
            centerPadding: '0px',
            arrows: true,
            slidesToShow: 3,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  arrows: false,
                  centerMode: true,
                  centerPadding: '0px',
                  slidesToShow: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  arrows: false,
                  centerMode: true,
                  centerPadding: '0px',
                  slidesToShow: 1
                }
              }
            ]


        });

         $(".mobile-nav-toggle").click(function(e){
          $(".mobile-navigation-container").toggleClass("animate");
          e.preventDefault();
        });

         var btn = $('#backTop');
         var scrollToSection = $(".scroll-to-section");

         scrollToSection.on('click', function(e) { 



          e.preventDefault(); 
          var sectionID = $(this).data('target');
          var sectionTop = $(sectionID).offset().top;
          console.log(sectionTop);
          console.log('clicked');
          $('html, body').animate({scrollTop: sectionTop}, '300');  


        });

        $(window).scroll(function() {
          if ($(window).scrollTop() > 300) {
            btn.addClass('show');
          } else {
            btn.removeClass('show');
          }
        });

        btn.on('click', function(e) {
          e.preventDefault();
          $('html, body').animate({scrollTop:0}, '300');
        });

         $(".fade-in-section-home").addClass("is-visible");

         $(window).scroll(function () {
            $('.fade-in-section, .fade-in-section-left, .fade-in-section-right').each(function (i) {
                var bottom_of_object = $(this).offset().top + ($(this).outerHeight() * 0.05);
                var bottom_of_window = $(window).scrollTop() + $(window).height();
                if (bottom_of_window > bottom_of_object) {
                    $(this).addClass('is-visible');
                }
            });
          });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
       
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
